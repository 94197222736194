import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const UserHeader = (props) => {
  // const location = props.location.pathname
  const queryParams = useLocation().pathname;
  const isCart = queryParams == "/checkout/cart";
  const isAddress = queryParams == "/checkout/address";
  const isPayment = queryParams == "/checkout/payment";

  return (
    <div className="mainHeader">
      <div className="logo-container">
        <a href="/" className="logo-anchor">
          <i></i>
        </a>
      </div>
      <Navbar variant="light">
        <Container>
           {/* <Navbar.Brand href="#home">Navbar</Navbar.Brand>  */}
          <Nav className="me-auto">
            <Nav.Link 
            href="/checkout/cart"
             className= {`order-stap ${isCart ? "activeNavlink borderUserHeader" : ""}`}
            >
              BAG
            </Nav.Link>
            <Nav.Link className="order-divider ">----------</Nav.Link>
            <Nav.Link
            href="/checkout/address"
                className={`order-stap ${isAddress ? "activeNavlink borderUserHeader" : ""}`} 
            >
              ADDRESS
            </Nav.Link>
            <Nav.Link className="order-divider ">----------</Nav.Link>
            <Nav.Link
            href="/checkout/payment"
            className={`order-stap ${isPayment ? "activeNavlink borderUserHeader" : ""}`} 
            >
              PAYMENT
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      {/* <div className="cart-link">
            <span
              className= {`order-stap ${isCart ? "activeNavlink borderUserHeader" : ""}`} 
            >
              BAG
            </span>
            <span className="order-divider ">----------</span>
            <span
              className={`order-stap ${isAddress ? "activeNavlink borderUserHeader" : ""}`} 
            >
              ADDRESS
            </span>
            <span className="order-divider ">----------</span>
            <span
              className={`order-stap ${isPayment ? "activeNavlink borderUserHeader" : ""}`}
            >
              PAYMENT
            </span>
         
      </div> */}
      <div class="secureContainer">
        <img
          src="https://constant.myntassets.com/checkout/assets/img/sprite-secure.png"
          class="secureIcon"
          width="26"
          height="28"
        />
        <div class="secure">100% SECURE</div>
      </div>
    </div>
  );
};

export default UserHeader;
