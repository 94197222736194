export const ADD_TO_CART='ADD_TO_CART '
export const MOVE_TO_CART='MOVE_TO_CART'
export const ADD_TO_WISHLIST='ADD_TO_WISHLIST'
export const MOVE_TO_WISHLIST='MOVE_TO_WISHLIST'
export const DELETE_WISHLIST_DATA='DELETE_WISHLIST_DATA'
export const INC="INC"
export const DEC='DEC'
export const DELETE='DELETE'
export const EMPTY='EMPTY'
export const DANGER = 'DANGER'
export const SUCCESS='SUCCESS'
export const CLOSE='CLOSE'
