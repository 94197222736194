import { combineReducers } from "redux";
import cartItem from "./Reducer";
import MToastReducer from "./MToastReduce";
import LoderReducer from "./LoderReducer";
import WishListItem from './WishlistReducer'

export default combineReducers({
  cartItem,
  MToastReducer,
  LoderReducer,
  WishListItem,
});
