import { ADD_TO_WISHLIST, DELETE_WISHLIST_DATA } from "../constants/constants";
import { EMPTY } from "../constants/constants";
const initialState = {
  WishListItems: [],
  totalPrice: 0,
  totalQty: 0,
  totalRackPrice: 0,
  // cartId: 0
};

const WishListItem = (state = initialState, action) => {
  const localCart = JSON.parse(localStorage.getItem("localCart"));
  if (JSON.parse(localStorage.getItem("wishList"))) {
    var { WishListItems, totalPrice, totalQty, totalRackPrice } = JSON.parse(
      localStorage.getItem("wishList")
    );
  } else {
    localStorage.setItem(
      "wishList",
      JSON.stringify({
        WishListItems: [],
        totalQty: 0,
        totalPrice: 0,
        totalRackPrice: 0,
      })
    );
    var { WishListItems, totalPrice, totalQty, totalRackPrice } = state;
  }
  let product;
  let index;
  let updatedPrice;
  let updatedQty;
  let variant;
  let updatedRackPrice;
  let updateCartId;
  switch (action.type) {
    case ADD_TO_WISHLIST:
      const check = WishListItems.find(
        (product) => product.variantId === action.data.id
      );
      if (!check) {
        if (action.data.sendTo == "cartData") {
          variant = action.data.cartProduct;
          updatedQty = totalQty + 1;
          updatedPrice = totalPrice + variant.pricing.sellPrice;
          updatedRackPrice = totalRackPrice + variant.pricing.rackPrice;

          const addtocart = {
            WishListItems: WishListItems.concat(variant),
            totalPrice: updatedPrice,
            totalQty: updatedQty,
            totalRackPrice: updatedRackPrice,
            cartId: updateCartId,
          };
          localStorage.setItem("wishList", JSON.stringify(addtocart));
          return addtocart;
        } else {
          variant =
            action.data.product.variant.variants[
              action.data.product.currentVariant
            ];
          product = action.data.product.variant;
          variant["ProductSubtitle"] = product.subTitle;
          variant["productTitle"] = product.title;
          variant["qty"] = 1;
          variant["TotalProductPrice"] =
            variant.pricing.sellPrice * variant.qty;
          variant["TotalProductRackPrice"] =
            variant.pricing.rackPrice * variant.qty;
          updatedQty = totalQty + 1;
          updatedPrice = totalPrice + variant.pricing.sellPrice;
          updatedRackPrice = totalRackPrice + variant.pricing.rackPrice;
          // updateCartId = generateCartId()

          const addtowishlist = {
            // WishListItems: [variant, ...WishListItems],
            WishListItems: WishListItems.concat(variant),
            totalPrice: updatedPrice,
            totalQty: updatedQty,
            totalRackPrice: updatedRackPrice,
            // cartId: updateCartId
          };
          localStorage.setItem("wishList", JSON.stringify(addtowishlist));
          return addtowishlist;
        }
      } else {
        console.log("this product is already in your wishlist");
        //     // toast.info('this product is already in your cart', {
        //     //     position: "top-right",
        //     //     autoClose: 2000,
        //     //     hideProgressBar: false,
        //     //     closeOnClick: true,
        //     //     pauseOnHover: false,
        //     //     draggable: false,
        //     //     progress: undefined,
        //     // });
        //     return state;
        //   const filtered = localCart.shoppingCart.filter((cartProduct) => cartProduct.variantId !== action.data.id);
        //   variant = action.data.cartProduct;
        //   console.log('variantcart',variant)
        //   updatedQty = localCart.totalQty - variant.qty;
        //   updatedPrice =localCart.totalPrice - variant.pricing.sellPrice;
        //   updatedRackPrice =localCart.totalRackPrice - variant.pricing.rackPrice;
        //   const movetowishlist = {
        //     shoppingCart: [...filtered],
        //     totalPrice: updatedPrice,
        //     totalQty: updatedQty,
        //     totalRackPrice: updatedRackPrice,
        //   };
        //   localStorage.setItem("localCart", JSON.stringify(movetowishlist));
        //   return movetowishlist;
        // }
        const filtered = localCart.shoppingCart.filter(
          (cartProduct) => cartProduct.variantId !== action.data.id
        );
        variant = action.data.cartProduct;
        updatedQty = localCart.totalQty - variant.qty;
        updatedPrice =
          localCart.totalPrice - variant.qty * variant.pricing.sellPrice;
        updatedRackPrice =
          localCart.totalRackPrice - variant.qty * variant.pricing.rackPrice;
        const Addtowishlist = {
          shoppingCart: [...filtered],
          totalPrice: updatedPrice,
          totalQty: updatedQty,
          totalRackPrice: updatedRackPrice,
        };
        localStorage.setItem("localCart", JSON.stringify(Addtowishlist));
        return Addtowishlist;
      }
    // case ADD_TO_WISHLIST:
    //     variant = action.data.product;
    //     updatedQty = ++totalQty;
    //     variant.TotalProductPrice = variant.qty * variant.pricing.sellPrice;
    //     variant.TotalProductRackPrice = variant.qty * variant.pricing.rackPrice
    //     updatedPrice = totalPrice + variant.pricing.sellPrice;
    //     updatedRackPrice = totalRackPrice + variant.pricing.rackPrice;
    //     const addtowishlist = {
    //         shoppingCart: shoppingCart.concat(variant),
    //         totalPrice: updatedPrice,
    //         totalQty: updatedQty,
    //         totalRackPrice: updatedRackPrice

    //     }
    //     localStorage.setItem("wishList", JSON.stringify(addtowishlist));
    //     return addtowishlist

    // case MOVE_TO_CART:
    //   variant = action.data.wishlist;
    //   updatedQty = localCart.totalQty + 1;
    //   updatedPrice = localCart.totalPrice + variant.pricing.sellPrice;
    //   updatedRackPrice = localCart.totalRackPrice + variant.pricing.rackPrice;
    //   const movetocart = {
    //     shoppingCart: localCart.shoppingCart.concat(variant),
    //     totalPrice: updatedPrice,
    //     totalQty: updatedQty,
    //     totalRackPrice: updatedRackPrice,
    //   };
    //   localStorage.setItem("localCart", JSON.stringify(movetocart));
    //   return movetocart;

    case DELETE_WISHLIST_DATA:
      const filtered = WishListItems.filter(
        (cartProduct) => cartProduct.variantId !== action.data.id
      );
      variant = action.data.wishlist;
      updatedQty = totalQty - variant.qty;
      updatedPrice = totalPrice - variant.qty * variant.pricing.sellPrice;
      updatedRackPrice =
        totalRackPrice - variant.qty * variant.pricing.rackPrice;

      const movetowishlist = {
        WishListItems: [...filtered],
        totalPrice: updatedPrice,
        totalQty: updatedQty,
        totalRackPrice: updatedRackPrice,
      };
      localStorage.setItem("wishList", JSON.stringify(movetowishlist));
      return movetowishlist;

    case EMPTY:
      const empty = {
        WishListItems: [],
        totalPrice: 0,
        totalQty: 0,
        totalRackPrice: 0,
      };
      localStorage.setItem("wishList", JSON.stringify(empty));

      return empty;

    default:
      return state;
  }
};
export default WishListItem;
