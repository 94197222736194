import React, { useContext } from "react";
import { BiRupee } from "react-icons/bi";
import { Link } from "react-router-dom";
import { db } from "../confing/confing";
import { CartContext } from "../global/CartContext";
import Header from "../header";
import firebase from "firebase/compat/app";

const WishList = (props) => {
  // localStorage.setItem("wishList", JSON.stringify({
  //     WishListItems: [],
  //     totalQty:0,
  //     totalPrice: 0,
  //     totalRackPrice: 0}))
  const userId = JSON.parse(localStorage.getItem("userId"));
  var { WishListItems, totalPrice, totalQty, totalRackPrice } = JSON.parse(
    localStorage.getItem("wishList")
  );
  var wishListData = [];
  if (WishListItems) {
    var emptyData = new Array(4 - (WishListItems.length % 4)).fill(null);

    WishListItems.map((wishlist) => {
      wishListData.push(wishlist);
    });
  }
  const wishListconcatData = wishListData.concat(emptyData);

  const deleteItem = (item) => {
    db.collection("wishList")
      .doc(`${userId}`)
      .update({
        WishListItems: firebase.firestore.FieldValue.arrayRemove(item),
        totalQty: totalQty - item.qty,
        totalPrice: totalPrice - item.qty * item.pricing.sellPrice,
        totalRackPrice: totalRackPrice - item.qty * item.pricing.rackPrice,
      });
    const filtered = WishListItems.filter(
      (wishlistProduct) => wishlistProduct.variantId !== item.variantId
    );
    localStorage.setItem(
      "wishList",
      JSON.stringify({
        WishListItems: [...filtered],
        totalQty: totalQty - item.qty,
        totalPrice: totalPrice - item.qty * item.pricing.sellPrice,
        totalRackPrice: totalRackPrice - item.qty * item.pricing.rackPrice,
      })
    );
    // window.location.reload();
  };
  return (
    <>
      <Header />
      {WishListItems.length > 0 && (
        <div className="searchProHeaderCon">
          <span> My Wishlist :</span>
          <span> {totalQty} item</span>
        </div>
      )}
      {WishListItems.length == 0 && (
        <div className="wishlistEmpty-container">
          <div className="wishlistEmpty-heading">YOUR WISHLIST IS EMPTY</div>
          <div className="wishlistEmpty-info">
            Add items that you like to your wishlist. Review them anytime and
            easily move them to the bag.
          </div>
          <div className="wishlistEmpty-icon-sprite wishlistEmpty-icon"></div>
          <div>
            <Link to="/" className="wishlistEmpty-button">
              CONTINUE SHOPPING{" "}
            </Link>
          </div>
        </div>
      )}
      <div className="wishlist-container">
        {wishListconcatData &&
          wishListconcatData.map((wishlist) => {
            return (
              <div className="wishListData-container pdr-30 pdb-30">
                {wishlist && (
                  <div style={{ position: "relative" }}>
                    <div
                      class="itemcard-removeIcon"
                      onClick={() =>
                        props.WishlistDataDeleteHandler({
                          id: wishlist.variantId,
                          wishlist,
                        })
                      }
                    >
                      <span class="myntraweb-sprite itemcard-removeMark sprites-remove"></span>
                    </div>
                    <img
                      className="wishListProductImage"
                      src={wishlist.images[0]}
                      alt="wishlistImg"
                      width="267px"
                      height="356px"
                    />
                    <div className="wishlisht-data-wrapper">
                      <p>{wishlist.ProductSubtitle}</p>
                      <div style={{ paddingBottom: "10px" }}>
                        <span className="totalSellPrice">
                          {" "}
                          Rs.{wishlist.TotalProductPrice}
                        </span>
                        <span className="totalRackPrice">
                          Rs.{wishlist.TotalProductRackPrice}
                        </span>
                      </div>
                      <button
                        onClick={() => {
                          props.addToCartHandler({
                            id: wishlist.variantId,
                            wishlist,
                            sendTo: "Wishlistdata",
                          });
                          deleteItem(wishlist);
                        }}
                      >
                        MOVE TO BAG
                      </button>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default WishList;
