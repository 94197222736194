import { connect } from 'react-redux'
import Cart from '../comopnents/storeFront/Cart'
import ProductDetails from '../comopnents/storeFront/ProductDetails'
import WishList from '../comopnents/storeFront/WishList'
import { AddToCart, AddToWishlist, Dec, Delete, Empty, Inc, moveToWishList, WishListDataDelete } from '../services/Actions/Action'



const mapStateToProps=state=>({
    data:state
})
const mapDispatchToProps=dispatch=>({
    addToCartHandler:data=>dispatch(AddToCart(data)),
    addToWishlistHandler:data=>dispatch(AddToWishlist(data)),
    IncHandler:data=>dispatch(Inc(data)),
    DecHandler:data=>dispatch(Dec(data)),
    DeleteHandler:data=>dispatch(Delete(data)),
    WishlistDataDeleteHandler:data=>dispatch(WishListDataDelete(data)),
    EmptyHandler:data=>dispatch(Empty(data)),
    // MoveToWishlistHandler:data=>dispatch(moveToWishList(data))

})
export default{
  Cart : connect(mapStateToProps,mapDispatchToProps)(Cart),
  WishList:connect(mapStateToProps,mapDispatchToProps)(WishList),
  ProductDetails: connect(mapStateToProps,mapDispatchToProps)(ProductDetails)

}